export default [
  {
    title: "Ombouwen van regelkasten",
    text:
      "De regelkast is het hart van het klimaatbeheersingssysteem en is voor het regelen van het binnenklimaat van groot belang. Het is belangrijk dat hiervoor een product en installateur gekozen word die bij uw wensen past. Bij Green Building Control kunnen wij door onafhankelijk te werk te gaan altijd de meest optimale keuze voor u maken.",
  },
  {
    title: "Service en onderhoud aan installaties",
    text:
      "Voor service en onderhoud installeren wij sensoren, bekabeling, meters en kleppen die installaties van genoeg informatie en controle voorzien. Ook vinden wij de problemen die in uw bestaande installatie aanwezig zijn lossen wij deze op.",
  },
  {
    title: "Optimaliseren van gebouwbeheersystemen",
    text:
      "Wij kunnen uw bestaande netwerk verbeteren of een nieuw netwerk inregelen. Deze kunnen wij ook aanpassen aan uw wensen. Hierbij denkt u aan het onderbrengen van klimaat, licht en beveiliging van uw huis of kantoor onder één apparaat.",
  },
  {
    title: "Projectmanagement en advies",
    text:
      "Door onze ervaring op meerdere vlakken van de techniek kunnen wij niet alleen adviseren op de meet-en regeltechniek maar ook andere aspecten van een project. Hierdoor kunnen wij oplossingen bieden voor hele projecten waarbij verschillende disciplines van een project elkaar zelden in de weg zitten.",
  },
]
