import styled from "styled-components"

export const Text = styled.p`
  color: #555;
  font-size: 1.6rem;
  letter-spacing: 1px;
  border-bottom: 3px solid #f9f9f9;
  padding-bottom: 3rem;
  line-height: 1.5;
  span {
    color: var(--colorPrimary);
  }
`
