import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"
import React from "react"
import { GreenHeader, TertiaryHeader } from "../../UI/TextHeaders"
const query = graphql`
  query products {
    allFile(filter: { relativeDirectory: { eq: "images/producten" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const ProductsContainer = styled.div`
  display: flex;
  //background-color: var(--colorWhiteDark);
  padding: 2rem;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  > div {
    width: 120px;
    margin-right: 2rem;
  }
`

const Products = () => {
  const data = useStaticQuery(query)
  const small = []
  const bigger = []
  data.allFile.edges.forEach(x => {
    const name = x.node.name
    if (name === "distech" || name === "ixon" || name === "johnson") {
      bigger.push(x.node)
    } else {
      small.push(x.node)
    }
  })
  return (
    <>
      <GreenHeader style={{ textAlign: "center", marginBottom: "2rem" }}>
        Onze Producten
      </GreenHeader>
      <ProductsContainer>
        {bigger.map((x, i) => (
          <div key={i * 3.14}>
            <Image fluid={x.childImageSharp.fluid} />
          </div>
        ))}
      </ProductsContainer>
      <ProductsContainer>
        {small.map((x, i) => (
          <div key={i * 3.14}>
            <Image fluid={x.childImageSharp.fluid} />
          </div>
        ))}
      </ProductsContainer>
    </>
  )
}

export default Products
