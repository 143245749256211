import styled from "styled-components"
import { GreenHeader } from "../../UI/TextHeaders"
import { Text } from "../../UI/TextContent"
import Button from "../../UI/Buttons"
import React from "react"
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
const IntroContainer = styled.div`
  display: grid;
  grid-gap: 5rem;
  grid-template-columns: repeat(2, minmax(25rem, 1fr));
  padding: 2rem 0;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const query = graphql`
  query MyQuery {
    file(name: { eq: "_20B0256" }) {
      base
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => {
  const data = useStaticQuery(query)
  return (
    <IntroContainer>
      <div>
        <GreenHeader>Over ons</GreenHeader>
        <Text>
          Na jarenlang gewerkt te hebben in verschillende takken van de
          techniek; elektrotechniek, klimaatbeheersing en installatietechniek
          zijn wij in 2016 Green Building Control begonnen. Met als doel alle
          projectonderdelen zelf uit te kunnen voeren. Hiermee kunnen wij ten
          alle tijden de service bieden die u van een installateur verwacht.
        </Text>
      </div>
      <GatsbyImage fluid={data.file.childImageSharp.fluid} />
    </IntroContainer>
  )
}
