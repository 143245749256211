import styled from "styled-components"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 3rem;
  /* align-content: start; */
  > div {
    overflow: hidden;
    position: relative;
    box-shadow: 0 1px 1.5rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    figure {
      height: 100%;
    }
    img {
      height: 100%;
      width: auto;
    }
    :hover {
      > div {
        top: 0;
      }
    }
    > div {
      transition: top 0.3s cubic-bezier(1, 0.33, 0.77, 1);
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 100%;
      left: 0;
      background: var(--colorSecondary);
      h5 {
        color: var(--colorWhite);
        font-size: 2rem;
        text-align: center;
        border-bottom: 1px solid var(--colorWhite);
        max-width: 80%;
      }
      h6 {
        color: var(--colorWhite);
        font-size: 1.6rem;
        text-align: center;
        max-width: 80%;
      }
      /* @media screen and (max-width: 720px) {
        position: relative;
      } */
    }
  }
`
const query = graphql`
  query featuredimages {
    allFile(filter: { relativeDirectory: { eq: "images/diensten_featured" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
export default props => {
  const data = useStaticQuery(query)
  const nodes = data.allFile.edges.map(x => {
    const name = x.node.name
      .split("Referenties_")[1]
      .split("_")
      .join(" ")

    return { ...x.node, name }
  })
  return (
    <ProjectGrid>
      {nodes.map(x => (
        <div key={x.name}>
          <figure>
            <Image fluid={x.childImageSharp.fluid} />
          </figure>
          <div>
            <h5>{x.name}</h5>
            {/* <h6>BAOPT/ Climotion project</h6> */}
          </div>
        </div>
      ))}
    </ProjectGrid>
  )
}
