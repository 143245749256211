import React, { useRef } from "react"
import Header from "../components/UI/Header"
import Layout from "../components/layout"

import {
  MainHeader,
  SecondaryHeader,
  GreenHeader,
} from "../components/UI/TextHeaders"
import Button, { ButtonBar } from "../components/UI/Buttons"
import SEO from "../components/SEO/SEO"
import Section from "../components/UI/Sections"
import Intro from "../components/PageComponents/Home/intro"
import ReferentiePreview from "../components/PageComponents/Home/referentiePreview"
import Dientslijst from "../components/PageComponents/Home/dientslijst"
import styled from "styled-components"
import { Text } from "../components/UI/TextContent"
import Products from "../components/PageComponents/Home/product"
const IntroContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, minmax(25rem, 1fr));
  padding: 2rem;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

export default props => {
  const jobSection = useRef(null)
  return (
    <Layout status={props.transitionStatus} page="home">
      <SEO title="Home" />
      <Header home>
        <div>
          <MainHeader>Technici die met u meedenken</MainHeader>
          {/* <SecondaryHeader>
            Green Building Control is een bedrijf in de meet- en regeltechniek
            die meedenkt met de eindgebruiker en opdrachtgever. Dit door zich
            aan te passen aan uw wensen op het gebied van duurzaamheid,
            efficiëntie, kosten en moderniteit.
          </SecondaryHeader> */}
          {/* <ButtonBar>
          <Button arrow to="/referenties" type="primary">
            Over ons
          </Button>
          <Button arrow to="/referenties" type="primary">
            Contact ons
          </Button>
        </ButtonBar> */}
        </div>
      </Header>
      <Section>
        <Intro></Intro>
      </Section>
      <Section gray>
        <ButtonBar align="center">
          <GreenHeader>Onze diensten</GreenHeader>
        </ButtonBar>

        <Dientslijst></Dientslijst>
        {/* <ButtonBar align="center">
        <Button to="/diensten" type="secondary">
          Details
        </Button>
      </ButtonBar> */}
      </Section>

      <Section green>
        <ButtonBar align="center">
          <SecondaryHeader>Referenties</SecondaryHeader>
        </ButtonBar>
        <ReferentiePreview></ReferentiePreview>
        <ButtonBar align="center">
          <Button
            type="primary"
            arrow
            to="/referenties"
            exit={{ length: 0.5 }}
            enter={{ length: 0.5 }}
          >
            Meer referenties
          </Button>
        </ButtonBar>
      </Section>
      <Section ref={jobSection}>
        <IntroContainer>
          <div>
            <GreenHeader>Werken bij green building control </GreenHeader>
            <Text>
              Door onze ervaring en kleinschalige opzet is Green Building
              Control een goede leeromgeving voor technisch talent. Dus ben jij
              leergierig en enthousiast voor een carrière in de techniek of een
              oude rot in het vak? Stuur een mail naar{" "}
              <span>bas@greenbuildingcontrol.nl</span> en wellicht zien we
              elkaar snel!
            </Text>
            {/* <Button type="secondary" to="/referenties" exit={{ length: 0.5 }}>
          Lees meer
        </Button> */}
          </div>
        </IntroContainer>
      </Section>
      <Section>
        <Products />
      </Section>
    </Layout>
  )
}
