import styled from "styled-components"
import React from "react"
import Diensten from "../../../constants/diensten"
import { Link } from "gatsby"
const List = styled.ul`
  width: 100%;
  text-align: center;
  margin-top: 3rem;
`

const DienstItem = styled.li`
  list-style: none;
  color: #555;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-shadow: 0.5px 0.5px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: centers;

  .title {
    margin-bottom: 10px;
    font-weight: 600;
    cursor: pointer;
  }
  .title::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 10px solid var(--colorSecondary);
    display: inline-block;
    margin-right: 1rem;
    cursor: pointer;
    transition: transform 0.3s;
    transform: rotate(${props => (props.open ? "90deg" : 0)});
  }
`

const Accordion = styled.div`
  transition: height 0.3s;
  height: ${props => (props.open ? "100px" : 0)};
  overflow: hidden;
  @media (max-width: 700px) {
    height: ${props => (props.open ? "160px" : 0)};
  }
  p {
    transition: opacity 0.5s;
    opacity: ${props => (props.open ? 1 : 0)};
  }
`

const Dienst = ({ dienst }) => {
  const [open, setOpen] = React.useState(false)
  return (
    <DienstItem open={open}>
      <div>
        <p className="title" onClick={() => setOpen(cur => !cur)}>
          {dienst.title}
        </p>
      </div>
      <div>
        {dienst.text ? (
          <Accordion open={open}>
            <p>{dienst.text}</p>
          </Accordion>
        ) : null}
      </div>
    </DienstItem>
  )
}

export default () => {
  return (
    <List>
      {Diensten.map(dienst => {
        return <Dienst key={dienst.title} dienst={dienst} />
      })}
    </List>
  )
}
